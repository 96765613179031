//  下单页面
import { mapState } from "vuex";
import AddressDetail from "@/components/address/address-detail/index.vue";
import RetailProductItem from "@/components/retail-order/retail-product-item/index.vue";
import { submitRetailOrder } from "@/service/retail-order/index.js";
import { getToken } from "@/utils/localStorage.js";
import { Toast } from "vant";

export default {
  name: "retail-preorder-page",
  components: { AddressDetail, RetailProductItem },
  computed: {
    //  已选商品件数
    sumCount() {
      return this.products.reduce((sum, p) => {
        return sum + p.quantity;
      }, 0);
    },
    ...mapState({
      products: (state) => state.retail.productList,
      address: (state) => {
        return {
          ...state.retail.addressInfo,
          fullAddress: state.retail.addressInfo.address,
        };
      },
      type: (state) => state.retail.type, //  零售类型：代理还是顾客
    }),
  },
  data() {
    return {
      submitLoading: false,
    };
  },
  methods: {
    onChooseAddress() {
      const { address: chooseAddress } = this;
      this.$router.push({
        name: "retail-address-list",
        query: {
          ...this.$route.query,
          chooseAddressId:
            chooseAddress &&
            (chooseAddress.retailUserId || chooseAddress.juniorUserId),
        },
      });
    },
    submit() {
      const { type, address, products } = this;
      this.submitLoading = true;
      submitRetailOrder({
        token: getToken(),
        type,
        addressInfo: { ...address, userName: address.name },
        productList: products,
        juniorId: address.juniorUserId,
      })
        .then(({ code, time }) => {
          Toast("提交发货订单成功～");
          this.$router.replace({
            name: "order-logistics",
            query: {
              code,
              createdAt: time,
              from: "retail",
              juniorId: address.juniorUserId,
            },
          });
          this.submitLoading = false;
        })
        .catch((err) => {
          this.submitLoading = false;
        });
    },
  },
  created() {},
};
